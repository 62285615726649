.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.chat-window {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.sidebar {
  width: 20%;
  height: 100%;
  background-color: #f0f0f0;
  transition: width 0.3s ease-in-out;
}

.hidden {
  width: 0;
  overflow: hidden;
}

.chat-container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
}

.user-message {
  background-color: #e6f2ff;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 5px;
}

.bot-message {
  background-color: #f0f0f0;
  margin-bottom: 5px;
  padding: 5px 10px;
  border-radius: 5px;
}

.input-container {
  display: flex;
  align-items: center;
  padding: 10px;
}

input {
  flex-grow: 1;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

button {
  padding: 8px 15px;
  border: none;
  background-color: #007bff;
  color: #fff;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

.toggle-sidebar {
  position: absolute;
  top: 50%;
  left: -20px;
  background-color: #007bff;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px 0 0 5px;
  cursor: pointer;
}

.toggle-sidebar:hover {
  background-color: #0056b3;
}

.top-bar {
  background-color: #007bff;
  color: #fff;
  padding: -50px;
  text-align: center;
  font-size: 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 10;
  height: 100;
}

.bottom-bar {
  background-color: #007bff;
  color: #fff;
  padding: 10px;
  text-align: center;
  font-size: 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}


